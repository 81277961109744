import { HTMLAttributes } from 'react';

import * as Styled from './styles';

/**
 * Error Message
 */
export interface ErrorMessageProps extends HTMLAttributes<HTMLDivElement> {
  error?: boolean;
  errorMsg?: string;
  errorLinkMsg?: string;
  errorLinkHref?: string;
  noMargin?: boolean;
  alwaysVisible?: boolean;
}

const ErrorMessage = ({
  error,
  errorMsg,
  errorLinkMsg,
  errorLinkHref,
  noMargin,
}: ErrorMessageProps) => {
  return error ? (
    <Styled.ErrorContainer row itemsCenter justifyBetween noMargin={noMargin}>
      <Styled.ErrorText variant="base" color="#C10C12">
        {errorMsg}
      </Styled.ErrorText>
      {errorLinkMsg && errorLinkHref && (
        <Styled.LinkText href={errorLinkHref}>{errorLinkMsg}</Styled.LinkText>
      )}
    </Styled.ErrorContainer>
  ) : null;
};

export default ErrorMessage;
