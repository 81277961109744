import styled from 'styled-components';
import FlexBox from '../FlexBox';
import Text from '../Text';

export const ErrorContainer = styled(FlexBox)<{ noMargin?: boolean }>`
  width: 100%;
  background: #fbc8ca;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 22px 30px;
  box-sizing: border-box;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : 19)}px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const ErrorText = styled(Text)`
  font-weight: 500;
  cursor: default;

  @media screen and (max-width: 1024px) {
    margin-bottom: 6px;
  }
`;

export const LinkText = styled.a`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #4f4f4f;
  cursor: pointer;
  text-decoration: none;
`;
