import styled, { css } from 'styled-components';
import FlexBox from '@app/components/atoms/FlexBox';
import Text from '@app/components/atoms/Text';
import Button from '@app/components/atoms/Button';

import IntroWeb from '/public/images/home/intro-web.png';
import IntroTablet from '/public/images/home/intro-tablet.png';
import IntroMobile from '/public/images/home/intro-mobile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled(FlexBox)`
  position: relative;
  width: 100%;
  max-width: 1550px;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: Inter;

  @media screen and (min-width: 1441px) {
    width: 100%;
  }
`;

export const ContainerWide = styled(FlexBox)`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: 1441px) {
    width: 100%;
  }
`;

export const ImageSection = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  padding-top: 6%;
  margin: 0 2.97% 225px;
  box-sizing: border-box;
  position: relative;
  aspect-ratio: 2.1;

  @media screen and (max-width: 1500px) {
    margin: 0 30px 225px;
  }

  @media screen and (max-width: 1200px) {
    aspect-ratio: 1;
    margin: 0 30px 280px;
    padding-top: 18%;
  }

  @media screen and (max-width: 1155px) {
    margin: 0 30px 315px;
  }

  @media screen and (max-width: 975px) {
    aspect-ratio: 1;
    margin: 0 0 315px;
  }

  @media screen and (max-width: 720px) {
    padding-top: 15%;
  }

  @media screen and (max-width: 530px) {
    padding-top: 12%;
  }

  @media screen and (max-width: 575px) {
    margin: 0 0 22px;
  }
`;

export const ImageSectionTitle = styled(Text)`
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  line-height: 60px;
  max-width: 785px;
  z-index: 999;

  @media screen and (max-width: 1024px) {
    font-size: 55px;
    line-height: 57px;
  }

  @media screen and (max-width: 720px) {
    font-size: 40px;
    line-height: 42px;
    max-width: 426px;
  }

  @media screen and (max-width: 480px) {
    font-size: 30px;
    line-height: 32px;
    max-width: 326px;
  }
`;

export const GetStartedBoxWrapper = styled(FlexBox)<{ hideMobile?: boolean }>`
  position: absolute;
  bottom: -110px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  display: ${({ hideMobile }) => (!hideMobile ? 'none' : 'flex')};

  @media screen and (max-width: 1200px) {
    bottom: -160px;
  }

  @media screen and (max-width: 1155px) {
    bottom: -190px;
  }

  @media screen and (max-width: 575px) {
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
    position: relative;
    bottom: unset;
    left: unset;
    right: unset;
  }
`;

export const GetStartedBox = styled(FlexBox)`
  background: #ffffff;
  padding: 26px 88px 37px 88px;
  box-sizing: border-box;
  width: 70%;
  box-shadow: 22px 22px 0px 0px #ececec;

  @media screen and (max-width: 1200px) {
    width: 85%;
  }

  @media screen and (max-width: 975px) {
    width: 80%;
  }

  @media screen and (max-width: 575px) {
    background: #f9f9f9;
    max-width: 100%;
    padding: 0 30px 74px;
    box-shadow: unset;
    width: unset;
  }
`;

export const GetStartedBoxTitle = styled(Text)`
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 600;
  color: #2cd1c0;
  margin-bottom: 10px;

  @media screen and (max-width: 500px) {
  }
`;

export const GetStartedBoxRow = styled(FlexBox)`
  width: 100%;
  gap: 24px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 43px;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column;
    gap: 23px;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const GetStartedBoxText = styled(Text)`
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  color: #4f4f4f;
`;

export const CustomButton = styled(Button)`
  @media screen and (max-width: 975px) {
    font-size: 18px;
  }
`;

export const TrustyBuyersSection = styled(FlexBox)`
  width: 100%;
  box-sizing: border-box;
  padding: 0 12%;
  margin-bottom: 92px;

  @media screen and (max-width: 1200px) {
    padding: 0 70px 0 30px;
    margin-bottom: 98px;
  }

  @media screen and (max-width: 975px) {
    padding: 0 30px;
  }
`;

export const TrustyBuyersTitle = styled(Text)`
  font-family: 'Inter';
  font-size: 50px;
  font-weight: 600;
  line-height: 53px;
  color: #4f4f4f;
  margin-bottom: 22px;
  max-width: 710px;

  span {
    color: #2faf92;
  }

  @media screen and (max-width: 975px) {
    font-size: 40px;
    margin-bottom: 26px;
  }
`;

export const TrustyBuyersDashedBordder = styled(FlexBox)`
  width: 710px;
  height: 100%;
  margin-bottom: 54px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 71px;
  }

  @media screen and (max-width: 975px) {
    width: 100%;
    margin-bottom: 34px;
  }
`;

export const TrustyBuyersLabel = styled(Text)`
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  color: #2cd1c0;
  margin-bottom: 16px;
  margin-top: 40px;
`;

export const TrustyBuyersRow = styled(FlexBox)`
  width: 100%;
`;

export const TrustyBuyersSaleWrapper = styled(FlexBox)`
  gap: 46px;
  max-width: 705px;

  @media screen and (max-width: 1400px) {
    max-width: 520px;
  }

  @media screen and (max-width: 975px) {
    max-width: 100%;
  }
`;

export const TrustyBuyersSaleTitle = styled(Text)`
  font-family: 'Inter';
  font-size: 55px;
  font-weight: 600;
  line-height: 47px;
  color: #4f4f4f;

  @media screen and (max-width: 975px) {
    font-size: 40px;
  }
`;

export const TrustyBuyersSaleText = styled(Text)`
  font-family: 'Inter';
  font-size: 27px;
  font-weight: 400;
  line-height: 32.68px;
  color: #4f4f4f;
`;

export const TrustyBuyersPorcentageWrapper = styled(FlexBox)`
  gap: 14px;
  position: relative;
  max-width: 300px;

  @media screen and (max-width: 975px) {
    display: none;
  }
`;

export const TrustyBuyersPorcentage = styled(Text)`
  font-family: 'Inter';
  font-size: 50px;
  font-weight: 600;
  line-height: 44px;
  color: #2faf92;
`;

export const TrustyBuyersPorcentageText = styled(Text)`
  font-family: 'Inter';
  font-size: 22px;
  font-weight: 400;
  line-height: 26.63px;
  color: #4f4f4f;
`;

export const TrustyBuyersQuote = styled(FlexBox)<{ right?: boolean }>`
  position: absolute;
  width: 83px;
  height: 83px;

  ${({ right }) =>
    right
      ? css`
          bottom: -40px;
          right: -40px;
        `
      : css`
          top: -40px;
          left: -40px;
        `};
`;

export const HomeownerRow = styled(FlexBox)`
  width: 100%;
  background: #50596f;
`;

export const Homeowner = styled(FlexBox)`
  width: 100%;
  width: 100%;
  height: 500px;
  padding: 56px 2.97% 72px;

  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    height: 700px;
  }
`;

export const ExplainerSection = styled(FlexBox)`
  width: 100%;
  height: 500px;
  padding: 56px 2.97% 72px;
  background: #50596f;
  box-sizing: border-box;

  @media screen and (max-width: 1450px) {
    height: fit-content;
    padding: 42px 30px 72px;
    height: 600px;
  }

  @media screen and (max-width: 1024px) {
    height: fit-content;
    padding: 42px 30px 72px;
  }

  @media screen and (max-width: 480px) {
    height: fit-content;
    padding: 10px 30px 80px;
  }
`;

export const ExplainerImageContainer = styled(FlexBox)<{ right?: boolean }>`
  min-width: 108px;
  height: 100%;

  ${({ right }) =>
    right
      ? css`
          margin-left: 20px;
          justify-content: flex-end;
        `
      : css`
          margin-right: 20px;
          justify-content: flex-start;
        `};

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const ExplainerBody = styled(FlexBox)`
  width: 100%;
  height: 100%;
  gap: 6.42%;
  margin-top: 35px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

export const ExplainerWrapper = styled(FlexBox)`
  width: 100%;
  box-sizing: border-box;
`;

export const ExplainerTitle = styled(Text)`
  color: #2cd1c0;
  font-size: 25px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 25px;
  margin-bottom: 20px;

  @media screen and (max-width: 1024px) {
    margin-top: 0px;
    height: unset;
  }

  @media screen and (max-width: 480px) {
    font-size: 25px;
    line-height: 27px;
    margin-top: 20px;
  }
`;

export const ExplainerSubtitle = styled(Text)`
  color: #fff;
  font-size: 45px;
  font-weight: 600;
  line-height: 47px;
  margin-bottom: 20px;

  @media screen and (max-width: 480px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const ExplainerParagraph = styled(Text)`
  color: #fff;
  font-size: 22px;
  font-weight: 200;
  line-height: normal;
`;

export const ExplainerButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ExplainerButtonText = styled(Text)`
  width: 140px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
`;

export const ExplainerButtonIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #ffffff;
`;

export const ExplainerDivider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: dashed #b2b5bc;
  margin: 70px 0 0px 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }

  @media screen and (max-width: 480px) {
    margin: 50px 0 0;
  }
`;

export const ProductChecklistRow = styled(FlexBox)`
  flex-direction: row;
  margin: 50px auto 100px auto;
  width: 90%;

  &:first-child {
    margin: 100px auto 30px auto;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin: 50px auto 30px auto;

    &:first-child {
      margin: 50px auto 30px auto;
    }
  }
`;
export const ProductChecklistContainer = styled(FlexBox)`
  max-width: 700px;
  padding: 0px 30px;
`;

export const ProductImageContainer = styled(FlexBox)`
  padding: 0px 30px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const ProductChecklistHeader = styled(Text)`
  color: #4f4f4f;
  font-size: 45px;
  font-weight: 600;
  line-height: 47px;
  margin-top: 25px;
  margin-bottom: 20px;

  @media screen and (max-width: 1024px) {
    margin-top: 0px;
    height: unset;
  }

  @media screen and (max-width: 480px) {
    font-size: 25px;
    line-height: 27px;
    margin-top: 20px;
  }
`;
export const ProductChecklistPara = styled(Text)``;
export const ProductChecklist = styled(FlexBox)`
  margin-left: 20px;
`;
export const ProductChecklistItem = styled(FlexBox)`
  flex-direction: row;
  margin-top: 10px;
  &:first-child {
    margin: 20px 0px 0px;
  }
`;
export const ProductChecklistCheckIcon = styled(FontAwesomeIcon)`
  margin-right: 15px;
`;

export const MoreDealsContainerWide = styled.div`
  background-color: #2f849a;
  width: 100%;
`;

export const MoreDealsContainer = styled(FlexBox)`
  padding-bottom: 50px;
  color: #fff;

  @media screen and (max-width: 700px) {
    height: fit-content;
    margin: 10px 30px 80px;
  }
`;
export const MoreDealsHeader = styled(Text)`
  font-size: 45px;
  color: #fff;
  margin: 0 auto;
  font-weight: 600;
  line-height: 47px;
  margin-top: 50px;
  margin-bottom: 20px;
`;
export const MoreDealsPara = styled(Text)`
  color: #fff;
  font-size: 20px;
  font-weight: 200;
  line-height: normal;
  max-width: 600px;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
export const MoreDealsGetStarted = styled(Button)`
  margin: 30px auto 37px;
`;
export const MoreDealsButtonRow = styled(FlexBox)`
  gap: 72px;
  line-height: 16px;
  margin: 0 auto;

  @media screen and (max-width: 975px) {
    flex-direction: column;
    gap: 22px;
  }
`;
export const MoreDealsSubButton = styled(FlexBox)`
  flex-direction: row;
  cursor: pointer;
`;
export const SubButtonIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  margin-bottom: 0px;
`;

export const NotBayAreaHeader = styled(FlexBox)`
  margin: 30px auto 0px;
  font-weight: 500;
  font-size: 24px;
`;
export const WatilistText = styled(Text)`
  margin: 10px auto 50px;
  text-align: center;
  width: 340px;
  span {
    color: #d47e5b;
    cursor: pointer;
  }
`;

export const HideWeb = styled.div`
  width: 100 %;
  display: block;
  @media screen and(min - width: 680px) {
    display: none;
  }
`;

export const HideMobile = styled.div`
  display: block;
  @media screen and(max - width: 680px) {
    display: none;
  }
`;
