import { NextSeo, NextSeoProps } from 'next-seo';
import React, { FunctionComponent } from 'react';
import { getVercelUrl } from '@app/utils/url';

interface Props extends NextSeoProps {
  title?: string;
  description?: string;
  imageUrl?: string;
  imageAlt?: string;
  path?: string;
  url?: string;
}
const Head: FunctionComponent<Props> = ({
  title = 'Home',
  description = 'Trusty is the first private, agent-to-agent networking platform that does the hard work of searching off-market for you.',
  imageUrl = 'https://static.trustyhomes.com/images/logo2.png',
  imageAlt = 'Trusty Logo',
  path = '',
  url,
  openGraph,
  // For additional tags
  ...props
}) => {
  return (
    <NextSeo
      canonical={url || getVercelUrl(path)}
      title={title}
      defaultTitle="Trusty - Limitless real estate"
      titleTemplate="Trusty | %s - Limitless real estate"
      description={description}
      openGraph={{
        type: 'website',
        url: url || getVercelUrl(path),
        title: `Trusty | ${title} - Limitless real estate`,
        description: description,
        images: [
          {
            url: imageUrl,
            alt: imageAlt,
          },
        ],
        site_name: 'Trusty',
        ...openGraph,
      }}
      twitter={{
        handle: '@trustyhomes',
        site: '@trustyhomes',
        cardType: 'summary_large_image',
      }}
      {...props}
    />
  );
};

export default Head;
