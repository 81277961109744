import Link from 'next/link';
import Image from 'next/image';
import { useState } from 'react';

import Head from '@app/components/molecules/Head';
import TemplateContainer from '@app/components/atoms/TemplateContainer';
import WaitlistFormModal from '@app/components/molecules/WaitlistFormModal';
import { Button } from '@app/components/atoms';

import { useWindowSize } from '@app/hooks/useWindowSize';

import IntroWeb from '/public/images/home/intro-web.png';
import IntroTablet from '/public/images/home/intro-tablet.png';
import IntroMobile from '/public/images/home/intro-mobile.png';
import DashedBorder from '/public/images/home/dashed-border.svg';
import SmallQuoteLeft from '/public/images/home/quote-left-small.svg';
import SmallQuoteRight from '/public/images/home/quote-right-small.svg';

import QuoteLeft from '/public/images/home/quote-left.svg';
import QuoteRight from '/public/images/home/quote-right.svg';
import ProductDiscoverHomes from '/public/images/home/product-discover-homes.png';
import ProductTurnListing from '/public/images/home/product-turn-listing.png';

import * as Styled from './styles';

interface MyWindow extends Window {
  Intercom?: (event: string, options?: unknown | null) => void;
}

const Home = () => {
  const { width } = useWindowSize();
  const isTablet = width <= 1200;
  const isMobile = width <= 975;

  const [waitlistModal, setWaitlistModal] = useState(false);

  const w = window as MyWindow;

  return (
    <div>
      <Head path="/" />

      <main>
        <TemplateContainer>
          <Styled.Container>
            <Styled.ImageSection>
              {isMobile ? (
                <Image
                  src={IntroMobile}
                  layout="fill"
                  objectFit="cover"
                  alt="Agents section image"
                />
              ) : isTablet ? (
                <Image
                  src={IntroTablet}
                  layout="fill"
                  objectFit="cover"
                  alt="Agents section image"
                />
              ) : (
                <Image
                  src={IntroWeb}
                  layout="fill"
                  objectFit="cover"
                  alt="Agents section image"
                />
              )}
              <Styled.ImageSectionTitle color="#4F4F4F">
                Agents are on the inside track for every home
              </Styled.ImageSectionTitle>
              <Styled.GetStartedBoxWrapper hideMobile>
                <Styled.GetStartedBox>
                  <Styled.GetStartedBoxTitle>Agents</Styled.GetStartedBoxTitle>

                  <Styled.GetStartedBoxRow row itemsCenter justifyBetween>
                    <Styled.GetStartedBoxText>
                      Put your network on autopilot to instantly discover which
                      of your peers have the intel you need for your next
                      off-market deal.
                    </Styled.GetStartedBoxText>
                    <Link href="/pricing" passHref>
                      <Button variant="quaternary" fontSize={20}>
                        Get started
                      </Button>
                    </Link>
                  </Styled.GetStartedBoxRow>
                </Styled.GetStartedBox>
              </Styled.GetStartedBoxWrapper>
            </Styled.ImageSection>

            <Styled.GetStartedBoxWrapper>
              <Styled.GetStartedBox>
                <Styled.GetStartedBoxTitle>Agents</Styled.GetStartedBoxTitle>

                <Styled.GetStartedBoxRow row itemsCenter justifyBetween>
                  <Styled.GetStartedBoxText>
                    Put your network on autopilot to instantly discover which of
                    your peers have the intel you need for your next off-market
                    deal.
                  </Styled.GetStartedBoxText>
                  <Link href="/pricing">
                    <Styled.CustomButton variant="quaternary" fontSize={25}>
                      Get started
                    </Styled.CustomButton>
                  </Link>
                </Styled.GetStartedBoxRow>
              </Styled.GetStartedBox>
            </Styled.GetStartedBoxWrapper>

            <Styled.TrustyBuyersSection>
              <Styled.TrustyBuyersTitle>
                Trusty has both your buyers and homeowner clients{' '}
                <span>covered</span>
              </Styled.TrustyBuyersTitle>
              <Styled.TrustyBuyersDashedBordder>
                <Image src={DashedBorder} alt="Dashed border" />
              </Styled.TrustyBuyersDashedBordder>

              <Styled.TrustyBuyersLabel>Buyer clients</Styled.TrustyBuyersLabel>
              <Styled.TrustyBuyersRow row justifyBetween itemsEnd>
                <Styled.TrustyBuyersSaleWrapper>
                  <Styled.TrustyBuyersSaleTitle>
                    Now every house is for sale
                  </Styled.TrustyBuyersSaleTitle>
                  <Styled.TrustyBuyersSaleText>
                    Grant your buyers exclusive access to relevant housing
                    inventory that they can’t find themselves. Uncover matching
                    homes and privately pursue them off-market.
                  </Styled.TrustyBuyersSaleText>
                </Styled.TrustyBuyersSaleWrapper>

                <Styled.TrustyBuyersPorcentageWrapper>
                  <Styled.TrustyBuyersQuote>
                    <Image src={SmallQuoteLeft} alt="Quote left" />
                  </Styled.TrustyBuyersQuote>

                  <Styled.TrustyBuyersPorcentage>
                    100%
                  </Styled.TrustyBuyersPorcentage>
                  <Styled.TrustyBuyersPorcentageText>
                    Real estate portals only show less than 1% of homes and miss
                    the other 99% that could be for sale.
                  </Styled.TrustyBuyersPorcentageText>

                  <Styled.TrustyBuyersQuote right>
                    <Image src={SmallQuoteRight} alt="Quote right" />
                  </Styled.TrustyBuyersQuote>
                </Styled.TrustyBuyersPorcentageWrapper>
              </Styled.TrustyBuyersRow>
            </Styled.TrustyBuyersSection>
          </Styled.Container>
          <Styled.ContainerWide>
            <Styled.HomeownerRow>
              <Styled.Container>
                <Styled.ExplainerSection row>
                  <Styled.ExplainerImageContainer>
                    <Image src={QuoteLeft} alt="Quote left" />
                  </Styled.ExplainerImageContainer>

                  <Styled.ExplainerBody row>
                    <Styled.ExplainerWrapper>
                      <Styled.ExplainerTitle>
                        Homeowner clients
                      </Styled.ExplainerTitle>
                      <Styled.ExplainerSubtitle>
                        Proactively surface future listings
                      </Styled.ExplainerSubtitle>
                      <Styled.ExplainerParagraph>
                        Privately indicate which homeowner clients may be open
                        to an off-market sale without public promotion.  As
                        matching qualified buyers are identified, alert your
                        homeowner clients of these opportunities.
                      </Styled.ExplainerParagraph>
                    </Styled.ExplainerWrapper>

                    <Styled.ExplainerDivider />

                    <Styled.ExplainerWrapper>
                      <Styled.ExplainerTitle>&nbsp;</Styled.ExplainerTitle>
                      <Styled.ExplainerSubtitle>
                        Stay connected to current and past clients
                      </Styled.ExplainerSubtitle>
                      <Styled.ExplainerParagraph>
                        Only 27% of buyers re-engage with the agent they bought
                        their house with. Lock-in these relationships with
                        relevant touch points about their property to ensure
                        you&apos;re always their first call.
                      </Styled.ExplainerParagraph>
                    </Styled.ExplainerWrapper>
                  </Styled.ExplainerBody>

                  <Styled.ExplainerImageContainer right>
                    <Image src={QuoteRight} alt="Quote right" />
                  </Styled.ExplainerImageContainer>
                </Styled.ExplainerSection>
              </Styled.Container>
            </Styled.HomeownerRow>
          </Styled.ContainerWide>
          <Styled.Container>
            <Styled.ProductChecklistRow>
              <Styled.ProductImageContainer>
                <Image
                  src={ProductDiscoverHomes}
                  alt="Dashed border"
                  placeholder="blur"
                  blurDataURL={
                    'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                  }
                />
              </Styled.ProductImageContainer>
              <Styled.ProductChecklistContainer>
                <Styled.ProductChecklistHeader>
                  Discover privately available homes
                </Styled.ProductChecklistHeader>
                <Styled.ProductChecklistPara>
                  Our technology matches your serious buyers to pocket listings,
                  off-market opportunities, and pre-market deals. We automate
                  off-market searches, inquiries and matchmaking to organize the
                  most relevant intel for you.
                </Styled.ProductChecklistPara>
                <Styled.ProductChecklist>
                  <Styled.ProductChecklistItem>
                    <Styled.ProductChecklistCheckIcon
                      icon={['fas', 'check']}
                      color="#59AD93"
                    />
                    Prioritize your top buyers and canvas the entire market
                  </Styled.ProductChecklistItem>
                  <Styled.ProductChecklistItem>
                    <Styled.ProductChecklistCheckIcon
                      icon={['fas', 'check']}
                      color="#59AD93"
                    />
                    Match all your qualified buyers with Trusty network homes
                    interested in a sale.{' '}
                  </Styled.ProductChecklistItem>
                  <Styled.ProductChecklistItem>
                    <Styled.ProductChecklistCheckIcon
                      icon={['fas', 'check']}
                      color="#59AD93"
                    />
                    Deliver for your buyer prospects with a comprehensive
                    off-market search and private deals unique to their needs.{' '}
                  </Styled.ProductChecklistItem>
                </Styled.ProductChecklist>
              </Styled.ProductChecklistContainer>
            </Styled.ProductChecklistRow>
            <Styled.ProductChecklistRow>
              <Styled.ProductChecklistContainer>
                <Styled.ProductChecklistHeader>
                  Turn listing prospects into transactions
                </Styled.ProductChecklistHeader>
                <Styled.ProductChecklistPara>
                  Discover buyer insights and announce private sales to agents
                  who matter. Ensure you are the first to hear when a hot buyer
                  matches your client’s home.
                </Styled.ProductChecklistPara>
                <Styled.ProductChecklist>
                  <Styled.ProductChecklistItem>
                    <Styled.ProductChecklistCheckIcon
                      icon={['fas', 'check']}
                      color="#59AD93"
                    />
                    Prioritize upcoming listings and generate activity long
                    before you list.
                  </Styled.ProductChecklistItem>
                  <Styled.ProductChecklistItem>
                    <Styled.ProductChecklistCheckIcon
                      icon={['fas', 'check']}
                      color="#59AD93"
                    />
                    Set qualified properties whose owners would listen to
                    serious interest.
                  </Styled.ProductChecklistItem>
                  <Styled.ProductChecklistItem>
                    <Styled.ProductChecklistCheckIcon
                      icon={['fas', 'check']}
                      color="#59AD93"
                    />
                    Manage all of your past and current homeowner relationships
                    with timely and relevant touch points.
                  </Styled.ProductChecklistItem>
                </Styled.ProductChecklist>
              </Styled.ProductChecklistContainer>
              <Styled.ProductImageContainer>
                <Image
                  src={ProductTurnListing}
                  alt="Dashed border"
                  placeholder="blur"
                  blurDataURL={
                    'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                  }
                />
              </Styled.ProductImageContainer>
            </Styled.ProductChecklistRow>
          </Styled.Container>
          <Styled.ContainerWide>
            <Styled.MoreDealsContainerWide>
              <Styled.MoreDealsContainer>
                <Styled.Container>
                  <Styled.MoreDealsHeader>
                    Do more deals in less time
                  </Styled.MoreDealsHeader>
                  <Styled.MoreDealsPara>
                    Trusty&apos;s state of the art agent to agent buyer and
                    property matchmaking technology connects the dots between
                    your serious buyers to pocket listings, off-market
                    opportunities, pre-market deals, and vice versa.
                  </Styled.MoreDealsPara>
                  <Link href="/pricing" passHref>
                    <Styled.MoreDealsGetStarted
                      variant="green"
                      padding="16px 14px"
                    >
                      Get started{' '}
                    </Styled.MoreDealsGetStarted>
                  </Link>
                  <Styled.MoreDealsButtonRow row>
                    <Styled.MoreDealsSubButton
                      onClick={() => w.Intercom && w.Intercom('show')}
                    >
                      Contact sales
                      <Styled.SubButtonIcon
                        icon={['fas', 'arrow-right']}
                        color="#fff"
                      />
                    </Styled.MoreDealsSubButton>

                    <Link href="/pricing" passHref>
                      <Styled.MoreDealsSubButton>
                        Pricing details
                        <Styled.SubButtonIcon
                          icon={['fas', 'arrow-right']}
                          color="#fff"
                        />
                      </Styled.MoreDealsSubButton>
                    </Link>
                  </Styled.MoreDealsButtonRow>
                </Styled.Container>
              </Styled.MoreDealsContainer>
            </Styled.MoreDealsContainerWide>
          </Styled.ContainerWide>
          <Styled.Container>
            <Styled.NotBayAreaHeader>
              Not in the SF Bay Area?
            </Styled.NotBayAreaHeader>
            <Styled.WatilistText>
              <span onClick={() => setWaitlistModal(true)}>
                Sign up for our waitlist
              </span>{' '}
              and you will be the first to know when we are in your market.
            </Styled.WatilistText>
          </Styled.Container>
        </TemplateContainer>

        <WaitlistFormModal
          isVisible={waitlistModal}
          onClose={() => setWaitlistModal(false)}
        />
      </main>
    </div>
  );
};

export default Home;
