export const getVercelUrl = (path?: string) => {
  const base = `http${
    process.env.NEXT_PUBLIC_VERCEL_URL?.includes('localhost') ? '' : 's'
  }://${
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? 'trustyhomes.com'
      : process.env.NEXT_PUBLIC_VERCEL_URL
  }`;
  if (!path) {
    return base;
  }
  return new URL(path, base).toString();
};

export const returnAWSUrl = (url: string) => {
  if (url.length > 0) {
    const replaceUrl = url.replace('https://', '');
    return `https://s3.us-west-2.amazonaws.com/${replaceUrl}`;
  }

  return '';
};
